import Inputmask from "inputmask";

document.addEventListener("DOMContentLoaded", function () {
    const hiddenFormInput = document.querySelectorAll(
        'input[name="page_title"]'
    );
    hiddenFormInput.forEach(function (input) {
        input.value = document.body.getAttribute("data-page");
    });

    const telSelectors = document.querySelectorAll('input[type="tel"]');

    for (const telSelector of telSelectors) {
        if (telSelector) {
            telSelector.setAttribute("autocomplete", "off");
            const mask = new Inputmask({
                mask: "+99 999 999 99 99",
                // definitions: {
                //     'A': {
                //         validator: "[0]",
                //         cardinality: 1,
                //         casing: "lower"
                //     },
                //     'B': {
                //         validator: "[5-9]",
                //         cardinality: 1,
                //         casing: "lower"
                //     },
                //     'C': {
                //         validator: "[3-9]",
                //         cardinality: 1,
                //         casing: "lower"
                //     },
                // },
                placeholder: "_",
            });
            mask.mask(telSelector);
        }
    }
});

document.addEventListener("DOMContentLoaded", function () {
    const links = document.querySelectorAll('a[href^="#"]');

    links.forEach((link) => {
        link.addEventListener("click", function (e) {
            e.preventDefault();

            const targetId = this.getAttribute("href").substring(1);
            const targetElement = document.getElementById(targetId);

            if (targetElement) {
                const headerOffset = 150;
                const elementPosition =
                    targetElement.getBoundingClientRect().top;
                const offsetPosition =
                    elementPosition + window.pageYOffset - headerOffset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                });
            }
        });
    });
});
