document.addEventListener('DOMContentLoaded', function () {
    const {ajax_url, themeUrl} = ajax_params;
    //
    // getAcfFields(ajax_url,'get_all_acf' , function (data){
    //     console.log(data)
    // })
});

function reinitCF7Form(parent) {
    const form = parent.querySelector('form');

    if (typeof wpcf7 !== 'undefined' && typeof wpcf7.initForm === 'function') {
        wpcf7.initForm(form);
    }

    if (typeof wpcf7 !== 'undefined' && wpcf7.cached && typeof wpcf7.refill === 'function') {
        wpcf7.refill(form);
    }
}


