import {addCustomClass, removeClassInArray, removeCustomClass} from "../functions/customFunctions";
import {fetchPage, filterButtonsHandler, updatePagination} from "./pagination";
import {getAcfFields} from "../functions/ajax-get-data";

document.addEventListener('DOMContentLoaded', function () {
    const buttons = document.querySelectorAll('.blog-nav button');
    const blogList = document.querySelector('.blog-list');
    const pagination = document.querySelector('.page-nav');
    const paginationBtns = document.querySelectorAll('.page-nav button');

    filterButtonsHandler(buttons, blogList, 'filter_blogs', function (postsContainer, data, category) {
        const { posts, total_pages, current_page } = data;

        postsContainer.innerHTML = '';
        const postItemsHTML = posts.map(post => `<li class="blog-list__item">${post}</li>`);
        postsContainer.innerHTML = postItemsHTML.join('');

        updatePagination(pagination, total_pages, current_page, category, postsContainer);

        setTimeout(function () {
            addCustomClass(postsContainer, 'loaded');
        }, 400);
    });


    paginationBtns.forEach(function (btn) {
        btn.addEventListener('click', function () {
            fetchPage(document.querySelector(`[data-category].active`).getAttribute('data-category'), 2, blogList);
        });
    });


    const category = localStorage.getItem('active_category');

    if (category) {
        const { ajax_url } = ajax_params;
        const params = `category_id=${category}&page=1`;

        getAcfFields(ajax_url, 'filter_blogs', params, function (data) {
            if (!data.success) {
                console.error('Error:', data.message);
                return;
            }

            const blogList = document.querySelector('.blog-list');
            callback(blogList, data, category);

            localStorage.removeItem('active_category');
        });
    }

    function callback(postsContainer, data, category) {
        const { posts, total_pages, current_page } = data;
        removeClassInArray(document.querySelectorAll('[data-category]'), 'active');
        addCustomClass(document.querySelector(`[data-category="${category}"]`), 'active');

        postsContainer.innerHTML = '';
        const postItemsHTML = posts.map(post => `<li class="blog-list__item">${post}</li>`);
        postsContainer.innerHTML = postItemsHTML.join('');

        updatePagination(pagination, total_pages, current_page, category, postsContainer);

        setTimeout(function () {
            addCustomClass(postsContainer, 'loaded');
        }, 400);
    }
});
