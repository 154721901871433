import {addCustomClass, removeCustomClass} from "../functions/customFunctions";
import vars from '../_vars'
import {commonFunction, modalClickHandler} from "./modals";


document.addEventListener('DOMContentLoaded', function () {
    const {activeClassMode, activeClass} = vars;
    const formWrappers = document.querySelectorAll('.wpcf7');
    const thanksID = document.querySelector('[data-thanks-id]').getAttribute('data-thanks-id');

    for (const formWrapper of formWrappers) {

        const formSubmitBtn = formWrapper.querySelector('button');

        function toggleLoader() {
            removeCustomClass(formWrapper, 'loader,loaded');
        }

        if (formWrapper && formSubmitBtn) {
            formSubmitBtn.addEventListener('click', function () {
                removeCustomClass(formWrapper, 'loaded');
                addCustomClass(formWrapper, 'loader');
            })

            formWrapper.addEventListener('wpcf7invalid', function (event) {
                setTimeout(function () {
                    addCustomClass(formWrapper, 'loaded')
                }, 500)
            }, false);


            formWrapper.addEventListener('wpcf7mailsent', function (event) {
                commonFunction();
                toggleLoader()

                setTimeout(function () {
                    modalClickHandler(false, `modal_${thanksID}`, activeClass, activeClassMode);
                }, 400)
            }, false);

        }

    }

});