import './components/dinamicHeight.js';
import './components/sliders.js';
import './components/modals.js';
import './components/get-ajax.js';
import './components/mobile-menu.js';
import './components/faq_filter.js';
import './components/blog_filter.js';
import './components/acc.js';
import './components/fancybox.js';
import './components/replaceEl.js';
import './components/form.js';
// import './components/gallery.js';
import './components/global.js';
// import './components/form-validate.js';
// import './components/anchor.js';

