import {responsiveSwiper} from "../functions/scripts/responsiveSwiper.js";
import Swiper from 'swiper/bundle';
import vars from '../_vars.js'
import {addCustomClass, fadeOut} from "../functions/customFunctions.js";

document.addEventListener('DOMContentLoaded', function (){
    const {mainSliders} = vars;

    mainSliders.forEach(function(slider){
        const container = slider.querySelector('.swiper-container');
        const pagination = slider.querySelector(".swiper-pagination");
        const nextBtn = slider.querySelector(".next");
        const prevBtn = slider.querySelector(".prev");
        const slideValue = container.getAttribute('data-slide');
        let flag = false;

        if (slideValue === "4") {
            flag = true;
        }

        const mainSwiper = new Swiper(container, {
            spaceBetween: 20,
            slidesPerView: flag ? 4 : 1,
            loop: flag ? false : true,
            speed: 1800,
            watchOverflow: flag ? true : false,
            observer: true,
            observeParents: true,
            autoplay: flag ? false : {
                delay: 3500,
            },
            navigation: {
                nextEl: nextBtn,
                prevEl: prevBtn,
            },
            pagination: {
                el: pagination,
                clickable: true,
            },
            breakpoints: {
                320: {
                    spaceBetween: flag ? 20 : 15,
                    slidesPerView: flag ? 1.5 : 1,
                    centeredSlides: flag ? true : false,
                },
                576: {
                    spaceBetween: flag ? 30 : 20,
                    slidesPerView: flag ? 2 : 1,
                },
                768: {
                    slidesPerView: flag ? 3 : 1,
                },
                1024: {
                    slidesPerView: flag ? 4 : 1,
                },
                1240: {
                    spaceBetween: flag ? 40 : 20,
                },
            },
        });
    });
})

document.addEventListener('DOMContentLoaded', function (){
    const {gallerySliders} = vars;

    gallerySliders.forEach(function(slider){
        const container = slider.querySelector('.swiper-container');
        const pagination = slider.querySelector(".swiper-pagination");
        const nextBtn = slider.querySelector(".next");
        const prevBtn = slider.querySelector(".prev");

        const gallerySwiper = new Swiper(container, {
            spaceBetween: 40,
            slidesPerView: 2,
            loop: true,
            speed: 1800,
            watchOverflow: true,
            observer: true,
            observeParents: true,
            autoplay: {
                delay: 3500,
            },
            navigation: {
                nextEl: nextBtn,
                prevEl: prevBtn,
            },
            pagination: {
                el: pagination,
                clickable: true,
            },
            breakpoints: {
                320: {
                    spaceBetween: 20,
                    slidesPerView: 1,
                },
                768: {
                    spaceBetween: 30,
                    slidesPerView: 2,
                },
                1024: {
                    spaceBetween: 40,
                    slidesPerView: 2,
                },
            },
        });
    });
})



