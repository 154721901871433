import { ElementReplacer } from '../functions/scripts/replacer';

const headerSocial = document.querySelector('.mobile .socials');
const parentDesktop = document.querySelector('.mobile__box');
const parentMobile = document.querySelector('.header__box');

const params = {
    element: headerSocial,
    parentDesktop: parentDesktop,
    parentMobile: parentMobile,
    breakpoint: 1024, // Убедитесь, что это число, а не строка
    mobilePlace: 'beforeend',
    desktopPlace: 'beforeend'
};

const elementReplacer = new ElementReplacer(params);