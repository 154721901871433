import {getAcfFields} from "../functions/ajax-get-data";
import {accReinit} from "./acc";
import {addCustomClass, removeClassInArray, removeCustomClass} from "../functions/customFunctions";
import {filterButtonsHandler} from "./pagination";

document.addEventListener('DOMContentLoaded', function () {
    const buttons = document.querySelectorAll('.faq-section .faq-nav button');
    const acc = document.querySelector('.faq-section .accordion[data-accordion-init]');

    filterButtonsHandler(buttons,acc, 'filter_posts', function (postsContainer, data, category){
        const {posts} = data;
        postsContainer.innerHTML = '';

        posts.forEach(post => {
            const postElement = document.createElement('li');
            postElement.classList.add('accordion__item');
            postElement.innerHTML = `
                <button class="accordion__btn" data-id='${post.ID}'>
                    <span class="pretitle">${post.title}</span>
                    <span class="accordion__icon">
                        <svg width="14" height="2">
                            <use href="${ajax_params.themeUrl}/assets/img/sprite/sprite.svg#minus"></use>
                        </svg>
                    </span>
                </button>
                <div class='accordion__content' data-content='${post.ID}'>
                    <div class="accordion__box">
                        ${post.content}
                    </div>
                </div>
            `;
            postsContainer.appendChild(postElement);
        });

        accReinit(postsContainer, "data-id", "data-content");

        setTimeout(function (){
            addCustomClass(postsContainer,'loaded');
        }, 400);
    });
});



